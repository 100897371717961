import { Container, Row, Col, Image } from "react-bootstrap";
import "./aboutus.css";
import CountUp from "react-countup";
import smile from "../../assets/about/smile.svg";
import Trust from "../../assets/about/trusted.svg";
import printer from "../../assets/about/printer.svg";
import users from "../../assets/about/persons.svg";

const Experience = () => {
  return (
    <>
      <div className="about-section" id="aboutus">
        <Container className="about-container">
          <Row>
            {/* Happy Customers */}
            <Col xs={12} sm={6} md={3} className="my-3">
              <div className="stat-card">
                <div className="image-body">
                  <Image src={smile} alt="smile" />
                </div>

                <h2>
                  <CountUp end={150} duration={2} />+
                </h2>
                <p>Happy Customers</p>
              </div>
            </Col>

            {/* Project Complete */}
            <Col xs={12} sm={6} md={3} className="my-3">
              <div className="stat-card">
                <div className="image-body">
                  <Image src={printer} alt="Printer" />
                </div>
                <h2>
                  <CountUp end={124} duration={2} />+
                </h2>
                <p>Project Complete</p>
              </div>
            </Col>

            {/* Experts Team */}
            <Col xs={12} sm={6} md={3} className="my-3">
              <div className="stat-card">
                <div className="image-body">
                  <Image src={users} alt="Users" />
                </div>
                <h2>
                  <CountUp end={180} duration={2} />+
                </h2>
                <p>Experts Team</p>
              </div>
            </Col>

            {/* Years of Experience */}
            <Col xs={12} sm={6} md={3} className="my-3">
              <div className="stat-card">
                <div className="image-body">
                  <Image src={Trust} alt="Award" />
                </div>
                <h2>
                  <CountUp end={10} duration={2} />+
                </h2>
                <p>Years of Experience</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Experience;
