import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faWrench,
  faClipboardCheck,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

const WhyChooseUsCards = () => {
  return (
    <Container className="mb-5">
      <h2 className="text-center display-6 fw-bold mt-2 mb-5">
        Why <span className="about-heading">Choose Us</span>
      </h2>
      <Row className="gy-4">
        {/* First Card - higher on large screens */}
        <Col xs={12} md={3}>
          <Card className="custom-card higher-card">
            <Card.Body>
              <div className="card-icon">
                <FontAwesomeIcon icon={faLeaf} size="3x" />
              </div>
              <Card.Title>Eco-Friendly Solutions</Card.Title>
              <Card.Text>
                We prioritize sustainable practices to minimize environmental
                impact, offering eco-friendly products and services.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Second Card - lower on large screens */}
        <Col xs={12} md={3}>
          <Card className="custom-card lower-card">
            <Card.Body>
              <div className="card-icon">
                <FontAwesomeIcon icon={faWrench} size="3x" />
              </div>
              <Card.Title>Customizable Services</Card.Title>
              <Card.Text>
                Our team works closely with you to deliver tailor-made solutions
                that perfectly fit your business needs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Third Card - higher on large screens */}
        <Col xs={12} md={3}>
          <Card className="custom-card higher-card">
            <Card.Body>
              <div className="card-icon">
                <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
              </div>
              <Card.Title>Wide Product Range</Card.Title>
              <Card.Text>
                From signage to printing, we offer a wide variety of products to
                meet all your business branding needs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Fourth Card - lower on large screens */}
        <Col xs={12} md={3}>
          <Card className="custom-card lower-card">
            <Card.Body>
              <div className="card-icon">
                <FontAwesomeIcon icon={faThumbsUp} size="3x" />
              </div>
              <Card.Title>Trusted by Businesses</Card.Title>
              <Card.Text>
                With a proven track record of success, we are trusted by
                businesses across industries for quality and reliability.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUsCards;
