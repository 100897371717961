import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import aboutImage from "../assets/about2.jpg";
import "../Pages/AboutUs/aboutus.css";
const Aboutus = () => {
  return (
    <div>
      <Container>
        <Row className="align-items-center mt-5 mb-4">
          <Col md={6} xs={12}>
            <div className="image-wrapper">
              <Image
                variant="top"
                src={aboutImage}
                alt="About Us"
                className="about-image"
              />
            </div>
          </Col>
          <Col md={6} xs={12} className="about-content">
            <h4 className=" text-secondary">About Us</h4>
            <h2 className="my-3 ">
              High Quality
              <span className="about-heading "> Signage & Printing </span>
              Services
            </h2>
            <p className="fs-6  text-secondary text-center text-md-start">
              Welcome to JRsignage, your reliable partner for high-quality
              signage that helps your business stand out. We specialize in
              creating innovative signs that communicate your message clearly
              and leave a lasting impression.
            </p>
            <p className="fs-6   text-secondary text-center text-md-start">
              Our team is dedicated to delivering custom solutions, including
              signs, banners, LED displays, and more. Whether you need frontlit,
              backlit, stainless steel, acrylic, wooden, or aluminium 3D letter
              signage, we have the expertise and technology to bring your ideas
              to life.
            </p>
            <p className="fs-6  mb-5 text-secondary text-center text-md-start">
              With a commitment to quality and customer satisfaction, JRsignage
              is a trusted name in the signage industry. From design to
              installation, we ensure your project is handled with care and
              professionalism.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
