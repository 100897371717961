import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import "./Home.css";
import homeimage from "../../assets/homeImage.png";
import leftImage from "../../assets/Home1.jpg";
import rightImage from "../../assets/Home2.jpg";
import Services from "../../Services/Services";
import Aboutus from "../../Components/Aboutus";
import Loader from "../../Components/Loader";
import TestimonialHead from "../Testimonial/TestimonialHead";
import WhyChooseUsCards from "../AboutUs/WhyChooseUsCards";
import Experience from "../AboutUs/Experience";

const HomeScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // Generating 5-10 water drops
  const generateWaterDrops = (numDrops) => {
    const drops = [];
    for (let i = 0; i < numDrops; i++) {
      drops.push(<div key={i} className={`water-drop drop-${i + 1}`}></div>);
    }
    return drops;
  };

  return (
    <>
      <div className="background-image">
        <Container className="home-section">
          <Row className="align-items-center px-4 p-sm-0">
            <Col md={6}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <div className="badge mb-4 ">
                  Digital Signage Printing Service
                </div>
                <h1 className="display-5 fw-bold mb-4 text-center text-md-start">
                  Elevate Your Brand with{" "}
                  <span className="custom-sign">Custom Signage</span> Solutions
                </h1>
                <p className="fs-6 mb-5 text-secondary text-center text-md-start">
                  "At <span>JR Signage</span>, we create signs that grab
                  attention. From outdoor displays to indoor guides, we help
                  your brand stand out. Let us design the perfect sign for you!"
                </p>
              </motion.div>
            </Col>

            <Col md={6}>
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <div className="image-stack">
                  <img
                    src={homeimage}
                    alt="Design 1"
                    className="gallery-image"
                  />
                </div>
              </motion.div>
            </Col>
          </Row>

          {/* Left animated image at bottom-left corner */}
          <motion.div
            className="animated-left-image"
            initial={{ y: 0 }}
            animate={{ y: [0, -20, 0] }}
            transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
          >
            <img src={leftImage} alt="Left Animation" className="side-image" />
          </motion.div>

          {/* Right animated image at top-right corner */}
          <motion.div
            className="animated-right-image"
            initial={{ y: 0 }}
            animate={{ y: [0, -20, 0] }}
            transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
          >
            <img
              src={rightImage}
              alt="Right Animation"
              className="side-image"
            />
          </motion.div>
        </Container>

        {/* Water drop animation */}
        <div className="water-drops">
          {generateWaterDrops(10)} {/* Generating 10 water drops */}
        </div>
      </div>
      <Experience />
      <Aboutus />
      <Services />
      <WhyChooseUsCards />
      <TestimonialHead />
    </>
  );
};

export default HomeScreen;
