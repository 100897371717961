import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import NavBar from "./Components/Header/NavBar";
import AboutUs from "./Pages/AboutUs/AboutUs";
import HomeScreen from "./Pages/Home/Home";
import SignageService from "./Services/ServicesList/SignageService";
import ContactFooter from "./Pages/ContactUs.jsx/ContactFooter";
import LedService from "./Services/ServicesList/LedService";
import MarkettingService from "./Services/ServicesList/MarkettingService";
import CustomBoardService from "./Services/ServicesList/CustomBoardService";
import SigBoardService from "./Services/ServicesList/SignBoards";
import PrintingService from "./Services/ServicesList/PrintingService";
import Loader from "./Components/Loader";
import ContactUsDetails from "./Pages/ContactDeatils/ContactUsDetails";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/signageservice" element={<SignageService />} />
        <Route path="/ledservice" element={<LedService />} />
        <Route path="/marketingservice" element={<MarkettingService />} />
        <Route path="/customproductservice" element={<CustomBoardService />} />
        <Route path="/signboardservice" element={<SigBoardService />} />
        <Route path="/printingservice" element={<PrintingService />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUsDetails />} />
      </Routes>
      <ContactFooter />
    </Router>
  );
}

export default App;
