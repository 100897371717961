import React, { useEffect, useState } from "react";
import "./aboutus.css";
import Loader from "../../Components/Loader";
import AboutHead from "./AboutHead";
import ChooseUs from "./WhyChooseUsCards";
import Experience from "./Experience";
import Aboutus from "../../Components/Aboutus";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AboutHead />

      <div className="about-section" id="aboutus">
        <Experience />
        <Aboutus />
        <ChooseUs />
      </div>
    </>
  );
};

export default AboutUs;
