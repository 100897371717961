import React, { useState } from "react";
import { Carousel, Card, Row, Col, Image } from "react-bootstrap";
import "./testimonial.css";
import testi1 from "../../assets/Testimonial/testi1.jpg";
import testi2 from "../../assets/Testimonial/testi2.jpg";
import testi3 from "../../assets/Testimonial/testi3.jpeg";
import testi4 from "../../assets/Testimonial/testi4.jpg";
import testi5 from "../../assets/Testimonial/testi5.jpg";
import testi6 from "../../assets/Testimonial/testi6.jpg";
const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const testimonials = [
    {
      text: `“JRsignage exceeded our expectations with their attention to detail and professionalism. The custom signage they created for our storefront has made a huge impact on our business visibility. Highly recommend!”`,
      author: "Community",
      image: testi1,
      stars: 5,
    },
    {
      text: `"The team at JRsignage was incredibly helpful throughout the entire process. From design to installation, everything was seamless and delivered on time. We couldn’t be happier with the results.”`,
      author: "Community",
      image: testi2,
      stars: 5,
    },
    {
      text: `Working with JRsignage was a breeze. They understood our vision and brought it to life perfectly. Our new acrylic signs look sleek and professional. We’ll definitely be coming back for future projects.`,
      author: "Community",
      image: testi3,
      stars: 5,
    },
    {
      text: `We were impressed by the innovative designs and flawless execution provided by JRsignage. Our new backlit signs have transformed our office space, making it modern and inviting.`,
      author: "Community",
      image: testi4,
      stars: 5,
    },
    {
      text: `We needed unique, eye-catching signage for our corporate headquarters, and JRsignage delivered. The team’s creativity and commitment to quality really stood out. We’ve received numerous compliments on our new look.`,
      author: "Community",
      image: testi5,
      stars: 5,
    },
    {
      text: `JRsignage helped us design custom LED signs that have made our brand stand out at industry events. Their quality and attention to detail are unmatched.`,
      author: "Community",
      image: testi6,
      stars: 5,
    },
  ];

  return (
    <div style={{ position: "relative", maxWidth: "900px", margin: "auto" }}>
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={3000}
        indicators={false}
        controls={false}
      >
        {testimonials.map((testimonial, index) => (
          <Carousel.Item key={index}>
            <Card className="carsusel-card  p-3">
              <Row>
                <Col
                  md={4}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Image
                    src={testimonial.image}
                    alt="Testimonial Image"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Card.Body>
                    <Card.Text className="testimonial-text">
                      {testimonial.text}
                    </Card.Text>
                    <Card.Title className="testimonial-author">
                      {testimonial.author}
                    </Card.Title>
                    <div className="testimonial-stars">
                      {"★".repeat(testimonial.stars)}
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Dots */}
      <div className="rounded-icon">
        {testimonials.map((_, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "13px",
              height: "13px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? "#ff5b5b" : "#f0c4c4",
              margin: "0 10px",
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
