import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";
import ServiceScroll from "../ServiceScrool";
import ServiceHead from "../ServiceHead";
import CommonService from "../CommonService";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import Broucher from "../../assets/IMAGES/broucher.jpg";
import leafelt from "../../assets/IMAGES/Archive/leafelt.jpg";
import VisitingCrad from "../../assets/IMAGES/Archive/VisitingCrad.jpg";
import stickers from "../../assets/IMAGES/Archive/stickers.jpg";
import certificate from "../../assets/IMAGES/Archive/certificate.jpg";
import brouchescroll from "../../assets/scrollImages/broucher.jpg";
import leafeltscroll from "../../assets/scrollImages/leafelt.jpg";
import VisitingCradscroll from "../../assets/scrollImages/visiting.jpg";
import stickersscroll from "../../assets/scrollImages/stickers.jpg";
import certificatescroll from "../../assets/scrollImages/certificate.jpg";
function MarkettingService() {
  const MarkettingServiceData = [
    {
      title: "Brochures",
      imgSrc: Broucher,
      points: [
        {
          head: "Informative",
          text: "Compact and detailed way to present information about products and services.",
        },
        {
          head: "Versatile Design",
          text: "Available in multiple folds and sizes to fit different content needs.",
        },
        {
          head: "High-Quality Prints",
          text: "Sharp text and vibrant images for a professional appearance.",
        },
        {
          head: "Cost-Effective Marketing",
          text: "Economical to produce in bulk, making it ideal for widespread distribution.",
        },
      ],
    },

    {
      title: "Leaflets",
      imgSrc: leafelt,
      points: [
        {
          head: "Cost-Effective",
          text: "Economical for mass distribution, perfect for marketing campaigns.",
        },
        {
          head: "Compact and Impactfu",
          text: "LED Single-page format that delivers key messages quickly.",
        },
        {
          head: "Customizable",
          text: "Various sizes and paper options to fit different promotional needs.",
        },
        {
          head: "Easy Distribution",
          text: "Lightweight and easy to hand out or include in mailings.",
        },
      ],
    },
    {
      title: "Visiting Cards",
      imgSrc: VisitingCrad,
      points: [
        {
          head: "Professional Look",
          text: "High-quality prints with various finishes like matte, gloss, and embossed.",
        },
        {
          head: "Custom Designs",
          text: "Personalized to reflect your brand and identity.",
        },
        {
          head: "Durable Materials",
          text: "Made to withstand frequent handling.",
        },
        {
          head: "Networking Tool",
          text: "ssential for making a lasting impression at business meetings and events.",
        },
      ],
    },
    {
      title: "Stickers",
      imgSrc: stickers,
      points: [
        {
          head: "Branding Tool",
          text: "Ideal for promoting your brand on various surfaces.",
        },
        {
          head: "Durable Adhesive",
          text: "Long-lasting and sticks well to different materials.",
        },
        {
          head: "Custom Shapes and Sizes",
          text: "Tailored to fit your design requirements.",
        },
        {
          head: "Versatile Usage",
          text: " Suitable for packaging, promotions, and decorative purposes.",
        },
      ],
    },
    {
      title: "Certificates",
      imgSrc: certificate,
      points: [
        {
          head: "Elegant Designs",
          text: "High-quality prints with professional finishes.",
        },
        {
          head: "Customizable",
          text: "Various templates and paper options to suit different occasions.",
        },
        {
          head: "Durable",
          text: "Made from high-quality materials that last.",
        },
        {
          head: "Official Recognition",
          text: "Ideal for awards, achievements, and recognitions.",
        },
      ],
    },
  ];
  const services = [
    { serviceName: "Brochures", serviceImage: brouchescroll },
    { serviceName: "Leaflets", serviceImage: leafeltscroll },
    { serviceName: "Visiting Cards", serviceImage: VisitingCradscroll },
    { serviceName: "Stickers", serviceImage: stickersscroll },
    { serviceName: "Certificates", serviceImage: certificatescroll },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our Marketting-Materials "
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService
          serviceData={MarkettingServiceData}
          serviceTitle=""
        />
      </Container>
    </>
  );
}

export default MarkettingService;
