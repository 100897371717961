import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";
import "./ContactFooter.css";
import logo from "../../assets/logoJr.png";
const ContactFooter = () => {
  return (
    <footer className="bg-dark text-light  pb-4 pt-5 " id="contactus">
      <Container>
        <Row>
          <Col
            md={4}
            xs={12}
            className="d-flex flex-column text-md-start text-center mb-3 mb-md-0"
          >
            <h3 className="mb-4">Address</h3>

            <a
              href="https://maps.app.goo.gl/FCV3EPML8RfyCnCx8"
              className="mb-2 footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMapMarkerAlt className="footer-icons fs-5" />
              5-811, Garden St, Ramaswamipalya, Lingarajapuram, Bengaluru,
              Karnataka 560043
            </a>

            <a href="tel:+919663493013" className="mb-2 footer-links ">
              <FaPhoneAlt className="footer-icons fs-5" />
              +919663493013
            </a>

            <a
              href="mailto:jrsignage2420@gmail.com"
              className="mb-2 footer-links "
            >
              <FaEnvelope className="footer-icons fs-5" />
              jrsignagegnanaraji@gmail.com
            </a>

            <a
              href="https://wa.me/9663493013"
              className="mb-2 footer-links "
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="footer-icons fs-5" />
              +919663493013
            </a>
          </Col>
          {/* Logo Section */}
          <Col md={4} xs={12} className="text-center mb-3 mb-md-0">
            <h3>JR Signage</h3>
            <img
              src={logo}
              alt="Company Logo"
              style={{
                display: "block",
                margin: "0 auto",
                width: "fit-content",
                maxWidth: "200px",
              }}
            />
          </Col>
          {/* Map Section */}
          <Col md={4} xs={12} className="text-center text-md-start">
            <h3>Find Us</h3>
            <div className="border border-secondary rounded">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3867.153350498151!2d77.63346687505356!3d13.010166087308784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16dec9fe199f%3A0x5c25ea79d6655caa!2s5%2C%20Garden%20St%2C%20Subbannaiah%20Palya%2C%20Banaswadi%2C%20Bengaluru%2C%20Karnataka%20560084!5e1!3m2!1sen!2sin!4v1729825518753!5m2!1sen!2sin"
                title="Google Map showing our office location"
                className="w-100 mb-n2"
                style={{ height: "200px", border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>

        {/* Copyright Section */}
      </Container>
      <Row className="mt-5 border-top border-secondary py-2">
        <Col className="">
          <p className="text-white-50  text-center">
            Copyright © <span className="text-white">2024 Jr Signage,</span> All
            Rights Reserved.
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default ContactFooter;
