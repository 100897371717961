import React from "react";
import { Spinner, Image } from "react-bootstrap";
import logo from "../assets/logoJr.png";
const Loader = () => {
  return (
    <div
      id="spinner"
      className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{
          width: "6rem",
          height: "6rem",
          color: "linear-gradient(to right, red, blue) ",
        }}
        role="status"
      />
      <Image
        src={logo}
        alt="Logo"
        style={{ maxWidth: "7%" }}
        className="position-absolute top-50 start-50 translate-middle"
      />
    </div>
  );
};

export default Loader;
