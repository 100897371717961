import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./contactus.css";
import arrow from "../../assets/about/inner-right-shape.svg";

const ContactHead = () => {
  return (
    <div className="contact-page d-flex justify-content-center align-items-center">
      <Container>
        {/* <div className="background-animation"></div> */}
        <Row>
          <Col>
            <h2 className="text-center display-5 fw-bold">Contact-Us</h2>
          </Col>
        </Row>
        {/* Image Layout */}
        <div className="image-container">
          <Image
            src={arrow}
            alt="Moving image 3"
            className="moving-image image-right"
          />
        </div>
      </Container>
    </div>
  );
};

export default ContactHead;
