import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./contactus.css";
import ContactHead from "./ContactHead";
import { Loader } from "lucide-react";

const ContactUsDetails = () => {
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ContactHead />
      <Container className="py-5">
        <Row>
          <h2 className="text-center fw-bold mb-5">
            If You Have Any Query, Please Feel Free{" "}
            <span className="contact-headSpan"> Contact Us</span>
          </h2>
          <Col xs={12} md={8} className="mx-auto">
            <Form>
              <Row>
                {/* Name Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>
                </Col>
                {/* Email Field */}

                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Phone Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                    />
                  </Form.Group>
                </Col>
                {/* Subject Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formSubject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" placeholder="Enter the subject" />
                  </Form.Group>
                </Col>
              </Row>

              {/* Message Field */}
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Write your message here..."
                />
              </Form.Group>

              {/* Order Button */}
              <div className="text-center">
                <Button disabled variant="primary" size="lg">
                  Send Message
                </Button>
              </div>
            </Form>
          </Col>
        </Row>

        {/* Google Map */}
        <Row className="mt-5">
          <Col xs={12} className="mx-auto">
            <h3 className="text-center fw-bold mb-4">
              Find Us <span className="contact-headSpan">Here</span>{" "}
            </h3>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3867.153350498151!2d77.63346687505356!3d13.010166087308784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16dec9fe199f%3A0x5c25ea79d6655caa!2s5%2C%20Garden%20St%2C%20Subbannaiah%20Palya%2C%20Banaswadi%2C%20Bengaluru%2C%20Karnataka%20560084!5e1!3m2!1sen!2sin!4v1729825518753!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Map"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUsDetails;
