import React from "react";
import { Row, Col } from "react-bootstrap";

const ServiceScroll = ({
  services,
  title,
  customClass = "",
  cardWidth = "400px",
  cardHeight = "280px",
  // imageSize = "150px", // New prop for even width and height
}) => {
  return (
    <div className={`mb-5 ${customClass}`}>
      <h2 className="servicecard-heading">
        {title || "Choose Your"}{" "}
        <span className="servicecard-span">Services</span>
      </h2>
      <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Row className="flex-nowrap p-2">
          {services.slice(0, 7).map((service, index) => (
            <Col
              key={index}
              className="d-inline-flex justify-content-center align-items-center m-3 m-md-0"
              style={{ flex: "0 0 auto", width: cardWidth }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center w-32 h-32 rounded-lg">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: cardWidth,
                    height: cardHeight,
                  }}
                >
                  {/* Set even width and height for image */}
                  <img
                    src={service.serviceImage}
                    alt={service.serviceName}
                    className="img-fluid"
                    style={{
                      width: "350px",
                      height: "230px",
                      objectFit: "cover", // Ensures the image fills the square
                      borderRadius: "8px", // Optional: Rounds the corners of the image
                    }}
                  />
                </div>
                <div className="service-card-content">
                  <p className="service-card-content-p text-center">
                    {service.serviceName}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ServiceScroll;
