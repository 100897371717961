import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./testimonial.css";
import Testimonial from "./Testimonial";

const TestimonialHead = () => {
  return (
    <div className={"testimonial-conatiner"}>
      <Container>
        <Row className="d-flex flex-column gap-5">
          <Col className="text-center">
            <h4 className="text-secondary">Testimonials</h4>
            <h2>
              What <span className="Testi-heading">Our Client Says</span>
            </h2>
          </Col>
          <Testimonial />
        </Row>
      </Container>
    </div>
  );
};

export default TestimonialHead;
