import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import "./ServicesList/ServiceList.css";
function CommonService({ serviceData, serviceTitle }) {
  return (
    <>
      <p
        className="fw-bold text-secondary text-center mb-4"
        style={{ fontSize: "24px" }}
      >
        Elevate your brand visibility with our comprehensive range of LED
        signage, marketing materials, custom boards, and printing services.
      </p>
      {serviceData.map((service, index) => (
        <Row
          key={index}
          className={`mb-4 ${index % 2 === 0 ? "default-bg" : "lite-gray-bg"}`}
        >
          <Col md={6} className={index % 2 === 0 ? "" : "order-md-2"}>
            <div className="service-image-container mb-4">
              <Image
                src={service.imgSrc}
                alt={service.title}
                className="service-image"
              />
            </div>
          </Col>

          <Col md={6} className={index % 2 === 0 ? "" : "order-md-1"}>
            <h3 className="image-head mb-4">{service.title}</h3>
            <ul className="ps-0">
              {service.points.map((point, i) => (
                <li key={i} className="mb-2 list-unstyled service-points ">
                  <FaCheckCircle className="text-primary me-2" />
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {point.head}
                  </span>
                  : {point.text}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      ))}
    </>
  );
}

export default CommonService;
